import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PasswordChecklist from "react-password-checklist"
import {useNavigate} from "react-router-dom";
import {Alert} from "@mui/lab";

const SignUpForm = () => {
    // for password show hide
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    // for Re password show hide
    const [rePasswordShown, setRePasswordShown] = useState(false);
    const toggleRePasswordVisiblity = () => {
        setRePasswordShown(rePasswordShown ? false : true);
    };

    // for validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string()
            .required("Email is required")
            .email("Entered value does not match email format"),
        password: Yup.string()
            .min(8, "Password must be at least 8 characters")
            .required("Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
        acceptTerms: Yup.bool().oneOf(
            [true],
            "Accept Terms and Conditions is required"
        ),
    });

    const [errorsPassword, setErrorsPassword] = useState('valid');

    const formOptions = {resolver: yupResolver(validationSchema)};
    // get functions to build form with useForm() hook
    const {register, handleSubmit, formState} = useForm(formOptions);
    const {errors} = formState;
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")
    const history = useNavigate();
    const [errorFromServer, setErrorFromServer] = useState("")
    const [showPasswordError, setShowPasswordError] = useState(false)
    const [alert, setAlert] = useState(false)
    console.log(window.location.hostname)
        const url = window.location.hostname === "localhost"  ? '' : process.env.REACT_APP_API_URI;
    function onSubmit(data, e) {
        if(errorsPassword === 'notvalid') {
            setErrorsPassword('notvalid');
            setShowPasswordError(true);
            return;
        }
        // display form data on success
        e.preventDefault();
        fetch(url + 'signup', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(res => {
                if(res.success === "success") {
                    setAlert(true);
                    //history('/login'); // Redirect to new page
                } else {
                    setErrorFromServer(res.error)
                }
            }
            );
    }

        function handlePassword(e) {
            setPassword(e.target.value);
        }
        function handleConfirmPassword(e) {
            setConfirmPassword(e.target.value);
        }

return (
    <>
        {alert &&
            <Alert style={{margin:'25px'}} severity="info">Please verify your email and login. Please check your inbox or spam folder</Alert>
        }
        <form className="user-data-form " onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-12">
                    <div className="input-group-meta mb-50">

                        <label>Name</label>
                        <input
                            type="text"
                            placeholder="Enter Full Name"
                            name="name"
                            {...register("name")}
                            className={` ${errors.name ? "is-invalid" : ""}`}
                        />

                        {errors.name && (
                            <div className="invalid-feedback">{errors.name?.message}</div>
                        )}
                    </div>
                </div>
                <div className="col-12">
                    <div className="input-group-meta mb-50">
                        {errorFromServer && (
                            <div className="invalid-feedback">{errorFromServer}</div>
                        )}
                        <label>Email</label>
                        <input
                            placeholder="Enter Your Email"
                            name="email"
                            type="text"
                            {...register("email")}
                            className={` ${errors.email ? "is-invalid" : ""}`}
                        />

                        {errors.email && (
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        )}
                    </div>
                </div>
                <div className="col-12">
                    <div className="input-group-meta mb-50">
                        <label>Password</label>
                        <input
                            placeholder="Enter Password"
                            name="password"
                            type={passwordShown ? "text" : "password"}
                            {...register("password")}
                            className={` ${errors.password ? "is-invalid" : ""}`}
                            onChange={handlePassword}
                        />

                        {errors.password && (
                            <div className="invalid-feedback">
                                {errors.password?.message}
                            </div>
                        )}
                        {showPasswordError  && (
                            <div className="invalid-feedback">
                                All password conditions should meet
                            </div>
                        )}
                        <span
                            className="placeholder_icon"
                            onClick={togglePasswordVisiblity}
                        >
                <span
                    className={
                        passwordShown ? "passVicon eye-slash" : "passVicon"
                    }
                >
                  <img src="images/icon/view.svg" alt=""/>
                </span>
              </span>
                        {/* End placeholder icon */}
                    </div>
                </div>
                <div className="col-12">
                    <div className="input-group-meta mb-25">
                        <label>Re-type Password</label>
                        <input
                            placeholder="Enter Password"
                            name="confirmPassword"
                            type={rePasswordShown ? "text" : "password"}
                            {...register("confirmPassword")}
                            className={` ${errors.confirmPassword ? "is-invalid" : ""}`}
                            onChange={handleConfirmPassword}
                        />
                        {errors.confirmPassword && (
                            <div className="invalid-feedback">
                                {errors.confirmPassword?.message}
                            </div>
                        )}
                        {/* End error msg */}
                        <span
                            className="placeholder_icon"
                            onClick={toggleRePasswordVisiblity}
                        >
                <span
                    className={
                        rePasswordShown ? "passVicon eye-slash" : "passVicon"
                    }
                >
                  <img src="images/icon/view.svg" alt=""/>
                </span>
              </span>

                        {/* End placeholder icon */}
                    </div>

                    <PasswordChecklist
                        rules={["minLength","specialChar","number","capital","lowercase","match"]}
                        minLength={8}
                        value={password}
                        valueAgain={confirmPassword}
                        onChange={(isValid) => {setErrorsPassword(isValid ? 'valid' : 'notvalid')}}
                    />
                </div>
                <div className="col-12">
                    <div className="agreement-checkbox d-flex justify-content-between align-items-center sm-mt-10">
                        <div className="position-relative">
                            <input
                                name="acceptTerms"
                                type="checkbox"
                                {...register("acceptTerms")}
                                id="acceptTerms"
                                className={` ${errors.acceptTerms ? "is-invalid" : ""}`}
                            />

                            <label htmlFor="acceptTerms">
                                By clicking "SIGN UP" I agree to the Terms and Conditions and
                                Privacy Policy.
                            </label>
                            {errors.acceptTerms && (
                                <div className="invalid-feedback">
                                    {errors.acceptTerms?.message}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* /.agreement-checkbox */}
                </div>
                {/*<div className="col-12">*/}
                {/*    <button type="submit" className="theme-btn-one mt-30 mb-50">*/}
                {/*        Sign Up*/}
                {/*    </button>*/}
                {/*</div>*/}
                {/* End .col */}
            </div>
        </form>
    </>
);
}
;

export default SignUpForm;
