import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./stripe.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

const stripePromise = loadStripe("pk_live_51OLKqEIJOb0gDi4yCL7X9arfO2txYie4WnzMsdaJPF12AI8g3WSET5f3N7p4ooZf4OXLq7sMvMfbxuorCpEVbPfK00l1asR39C");
const url1 = window.location.hostname === "localhost"  ? '' : process.env.REACT_APP_API_URI;
export default function Stripe() {
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {

        // Create PaymentIntent as soon as the page loads
        fetch(url1 + "create-payment-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className="App">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </div>
    );
}