import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {useNavigate} from 'react-router-dom';
import * as AWS from "aws-sdk";
import {jwtDecode} from "jwt-decode";

const LoginForm = () => {
    window.signinCallback = signinCallback
    // for password show hide
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    // for validation
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email is required")
            .email("Entered value does not match email format"),
        password: Yup.string().required("Password is required"),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};
    // get functions to build form with useForm() hook
    const {register, handleSubmit, formState} = useForm(formOptions);
    const [errorFromServer, setErrorFromServer] = useState("")
    const {errors} = formState;
    const history = useNavigate();

    function onSubmit(data, e) {
        // display form data on success
        e.preventDefault();
        let url = window.location.hostname === "localhost"  ? '' : process.env.REACT_APP_API_URI;
        url += 'signin'
        fetch(url , {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(res => {
                    if (res.success === "success") {
                        localStorage.setItem('user',data.email);
                        fetchAPIForSub();
                    } else {
                        setErrorFromServer(res.error);
                    }
                }
            );
        // Redirect to new page
    }

    const fetchAPIForSub = () => {
        const data = {
            "Email": localStorage.getItem("user"),
        }
        let urlsub = window.location.hostname === "localhost"  ? '' : process.env.REACT_APP_API_URI;
        fetch(urlsub + 'get_subscription', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(jsonData => {
                if(jsonData['items'].length != 0) {
                    let email = jsonData['items'][0]['Email']
                    let date = jsonData['items'][0]['date']
                    let startDate = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
                    if (email != null || date >= startDate) {
                        localStorage.setItem("date", date);
                    }
                }
                history('/project-management'); // Redirect to new page
            })
    }

    function signinCallback(authResult) {
        const decoded = jwtDecode(authResult['credential']);
        if(decoded['email'] != null) {
            localStorage.setItem('user',decoded['email']);
            fetchAPIForSub();
            // Redirect to new page
        } else {

        }
    }

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="user-data-form ">
                <div className="row">
                    <div className="col-12">
                        <div className="input-group-meta mb-80 sm-mb-70">
                            <div id="g_id_onload"
                                 data-client_id="852732382419-cc4hj3mrs617ir8b327flq775emehtgj.apps.googleusercontent.com"
                                 data-context="signin"
                                 data-ux_mode="popup"
                                 data-context="use"
                                 data-auto_prompt="false"
                                 data-callback="signinCallback">
                            </div>

                            <div className="g_id_signin"
                                 data-type="standard"
                                 data-shape="rectangular"
                                 data-theme="outline"
                                 data-text="signin_with"
                                 data-size="large"
                                 data-logo_alignment="left">
                            </div>
                        </div>
                        <div className="input-group-meta mb-80 sm-mb-70">
                            {errorFromServer && (
                                <div className="invalid-feedback">{errorFromServer}</div>
                            )}
                            <label>Email</label>
                            <input
                                placeholder="Enter Your Email"
                                name="email"
                                type="text"
                                {...register("email")}
                                className={` ${errors.email ? "is-invalid" : ""}`}
                            />
                            {errors.email && (
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="input-group-meta mb-25">
                            <label>Password</label>
                            <input
                                placeholder="Enter Password"
                                name="password"
                                type={passwordShown ? "text" : "password"}
                                {...register("password")}
                                className={` ${errors.password ? "is-invalid" : ""}`}
                            />
                            {errors.password && (
                                <div className="invalid-feedback">
                                    {errors.password?.message}
                                </div>
                            )}
                            <span
                                className="placeholder_icon"
                                onClick={togglePasswordVisiblity}
                            >
                <span
                    className={
                        passwordShown ? "passVicon eye-slash" : "passVicon"
                    }
                >
                  <img src="images/icon/view.svg" alt="ico"/>
                </span>
              </span>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="agreement-checkbox d-flex justify-content-between align-items-center">
                            <div>
                                <input type="checkbox" id="remember"/>
                                <label htmlFor="remember">Keep me logged in</label>
                            </div>
                        </div>
                        {/*  /.agreement-checkbox */}
                    </div>
                    {/*<div className="col-12">*/}
                    {/*    <button className="theme-btn-one mt-50 mb-50">Login</button>*/}
                    {/*</div>*/}
                </div>
            </form>
        </>
    );
};

export default LoginForm;
