import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import MegaMenu from "./mega-menu/MegaMenu";
import MegaMenuMobile from "./mega-menu/MegaMenuMobile";

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  const [date, setDate] = React.useState('');
  const changeBackground = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const logout = () => {
    localStorage.clear();
  }

  useEffect(() => {
    let date = localStorage.getItem('date');
    if(date != null) {
      let result = new Date(date);
      result.setDate(result.getDate() + 30);
      setDate(result.toDateString());
    }// this will fire on every change :(
  }, );

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-one bg-none  fixed"
            : "theme-main-menu sticky-menu theme-menu-one bg-none"
        }
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo"> <Link to={"/"}>Log4u </Link></div>
          {/*<div className="logo">*/}
          {/*  <Link to="/">*/}
          {/*    <img src="images/logo/deski_01.svg" alt="brand logo" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
          {/* End Logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div
                className="navbar-collapse collapse"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex align-items-center">
                  {/*<MegaMenu />*/}
                  {/* End MegaMenu */}

                  <ul style={{margin : '25px'}}>
                    { date ?
                    <li>
                    <Link to="/login" className="signIn-action" onClick={logout}>
                      Premium Access {date}
                      </Link>
                  </li>
                        : null }
                  </ul>
                  <ul>
                    <li>
                      <Link to="/login" className="signIn-action" onClick={logout}>
                        Logout
                      </Link>
                    </li>
                    {/*<li>*/}
                    {/*  <Link to="/signup" className="signUp-action">*/}
                    {/*    Get Started*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                  </ul>
                  {/* End right-button-group  */}
                </div>
              </div>
            </div>
          </nav>
          {/* End nav */}
        </div>

        <MegaMenuMobile />
        {/* 	End Mega Menu for Mobile */}
      </div>
      {/* /.theme-main-menu */}
    </>
  );
};

export default Header;
