import React from "react";

const FeatureContent = [
  {
    icon: "ils_15",
    meta: "Join Our Site",
    subTitle: `  It only takes 2 minutes. Set up is smooth and simple`,
  },
  {
    icon: "ils_16",
    meta: "Log your work",
    subTitle: `Choose which format to log your work such as STAR with AI help`,
  },
  {
    icon: "ils_17",
    meta: "Search your work before interview",
    subTitle: `Before interview just practice mock interview with your work log`,
  },
];

const FeatureNine = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="block-style-fourteen">
            {/*<div className="illustration">*/}
            {/*  /!*<img src={`images/assets/${val.icon}.svg`} alt="icon" />*!/*/}
            {/*</div>*/}
            <div className="title">{val.meta}</div>
            <p className="font-rubik">{val.subTitle}</p>
          </div>
          {/* /.block-style-fourteen */}
        </div>
      ))}
    </div>
    // /.row
  );
};

export default FeatureNine;
