import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What is the purpose of this AI-powered work log based interview preparation website?",
    desc: ` 1.  The primary purpose of the log4u.info is to help users refine and enhance their interview skills, particularly in the context of work-related experiences. 
                By leveraging artificial intelligence, the platform provides realistic simulations of work scenarios, allowing users to practice and improve their responses to 
                industry-specific questions. <br><br>
            2.  Log4u.info enable users to enter their work log based on STAR format <br>
            <p style="margin-left: 40px">
                      <b>Situation:</b> Set the context and give the necessary details of your example.<br>
                      <b>Task:</b> Describe what your responsibility was in that situation.<br>
                      <b>Action:</b> Explain exactly what steps you took to address it.<br>
                      <b>Result:</b> Share what outcomes your actions achieved. <br>
            </p>
                This work logs are saved in a secure location and users can edit/view/delete work log.<br><br>
            3.  When the user ready for interview, they can refer their work log and use AI platforms to help them generate mock interview questions based on 
                either their resume or work logs. `,
    expand: "b",

  },
  {
    title: "What measures are in place to ensure confidentiality?",
    desc: `We don't want users to enter full details of work logs, obviously don't enter any NPI and Non-disclosed information's. Log4u.info will give you a 
          number of responsibilities pre-determined by user's job title. We use AI to generate the logs based on user's job title and responsibility in STAR format. 
          Once AI generate the STAR format user can edit it or save as is. The privacy, confidentiality and security are important for Log4u.info platform.Log4u provided its own AI
          which is very secured and uses in-house AI LLM models generation.
          Please visit for <a style= "text-decoration: underline" href="https://log4u.info/privacy.html">privacy policy page </a>`,
    expand: "a",
  },
  {
    title: "What is included in the free version of the platform?",
    desc: `Log4u.info uses three main AI platforms for now, ChatGPT is restricted to 6 calls per user but Gemini AI is free.
    If users want to use
    ChatGPT above 6 calls they need to subscribe premium for $2.99 per month for website. Log4u.info won't charge monthly since it is pay as you go.However, ios and android apps
    are completely free but ChatGPT is limited to 6 calls and there is no subscription for premium`,
    expand: "c",
  },
  {
    title: "How can I provide feedback on the platform's features?",
    desc: `Please use our email mailtolog4u@gmail.com`,
    expand: "d",
  },
  {
    title: "How do I create an account on the website?",
    desc: ` Users can access the site without registered by using Google account. However we recommend to register with us. `,
    expand: "e",
  },
  // {
  //   title: "	What do you look for in a founding team?",
  //   desc: `mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.`,
  //   expand: "d",
  // },
  // {
  //   title: " Do you recommend Pay as you go or Pre pay?",
  //   desc: `mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.`,
  //   expand: "e",
  // },
  // {
  //   title: " Can I pass the fees on to my customers?",
  //   desc: `mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.`,
  //   expand: "f",
  // },
  // {
  //   title: "Roem ipsum dummy  here will some questions?",
  //   desc: `mea case duis tollit et. Etiam nusquam set minium eu sea, ei tale paulo elab. Noluisse mnesarch Et is vero incorrupte eos deserunt quaeren.`,
  //   expand: "g",
  // },
];

const FaqFour = () => {
  return (
    <div
      className="accordion-style-four"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="faq-wrraper">
        <Accordion preExpanded={["b"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <div dangerouslySetInnerHTML={{__html: item.desc}}></div>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqFour;
