import React from "react";
import { Link } from "react-router-dom";
import FeaturesEight from "../features/FeaturesEight";

const HeroBannerFour = () => {
    const mystyle = {
        position: "relative",
        display: "inline-block",
        width: "240px",
        height: "80px",
        borderRadius: "16px",
        overflow: "hidden",
        marginRight :"5px",
        backgroundColor: "black"
    };
    const mystyleImg = {
        position: "absolute",
        width: "var(--width)",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    };
    const mystyleImgApple = {
        position: "absolute",
        width: "var(--width)",
        top: "25%",
        left: "25%",
        transform: "scale(1.75)"
    };
  return (
    <div className="hero-banner-four">
      <img
        src="images/shape/100.svg"
        alt="shape"
        className="shapes shape-four"
      />
      <img
        src="images/shape/101.svg"
        alt="shape"
        className="shapes shape-five"
      />
      <img
        src="images/shape/102.svg"
        alt="shape"
        className="shapes shape-six"
      />
      <img
        src="images/shape/103.svg"
        alt="shape"
        className="shapes shape-seven"
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-lg-last">
            {/*<div className="illustration-holder">*/}
            {/*  <img*/}
            {/*    src="images/assets/ils_14.svg"*/}
            {/*    alt="shape"*/}
            {/*    className="illustration"*/}
            {/*  />*/}
            {/*  <img*/}
            {/*    src="images/assets/ils_14.1.svg"*/}
            {/*    alt="shape"*/}
            {/*    className="shapes shape-one"*/}
            {/*  />*/}
            {/*  <img*/}
            {/*    src="images/assets/ils_14.2.svg"*/}
            {/*    alt="shape"*/}
            {/*    className="shapes shape-two"*/}
            {/*  />*/}
            {/*  <img*/}
            {/*    src="images/assets/ils_14.3.svg"*/}
            {/*    alt="shape"*/}
            {/*    className="shapes shape-three"*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<p className="review-text">*/}
            {/*  Over <span>150,000+ client</span> all over the world.*/}
            {/*</p>*/}
          </div>
          {/* End .col */}

          <div className="col-lg-8 order-lg-first">
            <div className="text-wrapper">
              <h1 data-aos="fade-up" data-aos-duration="1200">
                <span>Log Work for </span> practice mock interview based on your logs.
              </h1>
              {/*<p*/}
              {/*  className="sub-text"*/}
              {/*  data-aos="fade-up"*/}
              {/*  data-aos-duration="1200"*/}
              {/*  data-aos-delay="100"*/}
              {/*>*/}
              {/*  We are here to help you to log your work in STAR and other formats by using AI. Later practice mock interview with your data using copilot AI.*/}
              {/*</p>*/}
              {/*<Link*/}
              {/*  to="/login"*/}
              {/*  className="theme-btn-five"*/}
              {/*  data-aos="fade-up"*/}
              {/*  data-aos-duration="1200"*/}
              {/*  data-aos-delay="200"*/}
              {/*>*/}
                {" "}
                {/*Let’s get started*/}
              {/*</Link>*/}
                <br/>
                <div style={{alignItems:"center",marginTop:'25px'}}>
                <a style={mystyle} href='https://play.google.com/store/apps/details?id=com.log4uapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={mystyleImg} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                <a style={mystyle} href="https://apps.apple.com/us/app/log4uapp/id6474574009?itsct=apps_box_badge&amp;itscg=30200"><img style={mystyleImgApple} src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1704585600" alt="Download on the App Store"></img></a>
                </div>
            </div>
            {/* /.text-wrapper */}
          </div>
          {/* End .col */}
        </div>
      </div>

      {/*<div className="fancy-feature-eight mt-160 md-mt-100">*/}
      {/*  <div className="container">*/}
      {/*    <div className="bg-wrapper">*/}
      {/*      <FeaturesEight />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* /.fancy-feature-eight */}
    </div>
  );
  //  {/* /.hero-banner-four */}
};

export default HeroBannerFour;
