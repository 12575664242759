import React, {useEffect, useState} from "react";
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel, Radio, RadioGroup, TextareaAutosize
} from "@mui/material";
import Stripe from "../Stripe";
import Grid from "@mui/material/Grid";
import ReactSpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition';
import {FaMicrophone, FaPlay, FaStop, FaStopCircle, FaSync} from "react-icons/fa";

const Copilot = () => {
    const url = window.location.hostname === "localhost" ? '' : process.env.REACT_APP_API_URI;
    const [open, setOpen] = React.useState(false);
    const [showCircle, setShowCircle] = React.useState(false);
    const {transcript, resetTranscript} = useSpeechRecognition();
    const [aitext, setAitext] = React.useState('');
    const [openCircle, setOpenCircle] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [data, setData] = React.useState("universe");
    const [model, setModel] = React.useState("chatgpt");

    const handleChangeData = (event) => {
        setData(event.target.value)
    };
    const handleChangeModel = (event) => {
        setModel(event.target.value)
    };
    const startRecognition = () => {
        ReactSpeechRecognition.startListening({continuous: true})
    };

    const stopRecognition = () => {
        ReactSpeechRecognition.stopListening()
    };
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            stopRecognition()
            if (transcript && transcript.length > 6) {
                fetchAPIMock(transcript).then(result => setAitext(result));
            }
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [transcript])

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const resetAll = (event) => {
        setAitext('');
    };
    const fetchAPIMock = (param) => {
        setShowCircle(true);
        setOpenCircle(true);
        const formData = new FormData()

        formData.append('Email', localStorage.getItem('user'))
        formData.append('data', data)
        formData.append('model', model)
        formData.append('date', localStorage.getItem('date'))
        return fetch(url + 'getMockWithResume/' + param, {
            method: 'POST',
            headers: {},
            body: formData,
        })
            .then(response => response.json())
            .then(jsonData => {
                setShowCircle(false);
                setOpenCircle(false);
                if (jsonData.response === 'overlimit') {
                    setOpenDialog(true);
                    return jsonData.response;
                } else {
                    return jsonData.response;
                }
            })
    }
    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Log4U"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You don't have premium access. To buy premium access for $2.99 per month(no auto
                        bill), please use the following payment.
                        <Stripe></Stripe>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>


            {showCircle ?
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={openCircle}
                    onClick={handleClose}
                >
                    <CircularProgress style={{margin: '10px'}}/>
                </Backdrop>
                : null
            }
            <Grid container gutter={16} justify='center' direction="row">
                <Grid item sm={6} style={{marginTop: "25px"}}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="universe"
                        name="radio-buttons-group"
                        onChange={handleChangeData}
                        value={data}
                    >
                        <FormControlLabel value="universe" control={<Radio/>} label="Universe"/>
                        <FormControlLabel value="self" control={<Radio/>} label="My Data"/>
                    </RadioGroup>
                </Grid>
                <Grid item sm={6} style={{marginTop: "25px"}}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="chatgpt"
                        name="radio-buttons-group"
                        onChange={handleChangeModel}
                        value={model}
                    >
                        <FormControlLabel value="chatgpt" control={<Radio/>}
                                          label="Chat-GPT - Premium $2.99 for a month after 6 calls"/>
                        <FormControlLabel value="gemini" control={<Radio/>}
                                          label="Gemini AI - Free Unlimited"/>
                    </RadioGroup>
                </Grid>
            </Grid>
                <h6>Press start button for real time transcription</h6>
                <Grid container gutter={16} justify='center' direction="row">
                    <Grid item sm={1} style={{marginBottom:1,marginTop:15}}>
                        <label htmlFor="filePicker" style={{padding:"5px 5px"}}>
                            Start
                        </label>
                        <FaMicrophone size={25} onClick={startRecognition} style={{ color:'green'}}/>
                    </Grid>
                    <Grid item sm={1} style={{marginBottom:1,marginTop:15}}>
                        <label htmlFor="filePicker" style={{padding:"5px 5px"}}>
                            Stop
                        </label>
                        <FaStop size={25} onClick={stopRecognition} style={{ color:'red'}}/>
                    </Grid>
                    <Grid item sm={1} style={{marginBottom:1,marginTop:15}}>
                        <label htmlFor="filePicker" style={{padding:"5px 5px"}}>
                            Reset
                        </label>
                        <FaSync size={25} style={{ color:'orange'}} onClick={() => {
                            resetTranscript();
                            resetAll();
                        }}/>
                    </Grid>
                </Grid>
                <Grid item sm={12} style={{marginTop: "25px"}}>
                    <TextareaAutosize
                        maxRows={25}
                        minRows={10}
                        placeholder=""
                        defaultValue=''
                        value={transcript}
                        style={{width: "100%"}}
                    />
                </Grid>

                <Grid item sm={12} style={{marginTop: "25px"}}>
                    <TextareaAutosize
                        maxRows={25}
                        minRows={10}
                        placeholder=""
                        defaultValue=''
                        value={aitext}
                        style={{width: "100%"}}
                    />
                </Grid>
        </div>
    )
}
export default Copilot;