import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header/Header";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ReactSpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition';
import {
    Backdrop,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    InputLabel,
    Link,
    MenuItem,
    Modal,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextareaAutosize,
    Typography
} from "@mui/material";
import {FaUpload, FaPlay, FaMicrophone, FaStop, FaStopCircle, FaFileExport, FaCircle, FaSync} from 'react-icons/fa';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {DataGrid} from '@mui/x-data-grid';
import dayjs from "dayjs";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Autocomplete} from "@mui/lab";
import {useForm} from "react-hook-form";
import stripe from "../Stripe"
import Stripe from "../Stripe";
import {Label} from "@mui/icons-material";
import Copilot from "./Copilot";
import {FaCircleArrowLeft, FaCircleChevronDown, FaComments} from "react-icons/fa6";
import transition from "react-transition-group/Transition";


const ProjectManagement = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const validationSchema = Yup.object().shape({
        category: Yup.string().required("Responsibility is required"),
    });

    const [file, setFile] = useState();
    const url = window.location.hostname === "localhost" ? '' : process.env.REACT_APP_API_URI;
    const [open, setOpen] = React.useState(false);
    const [openView, setOpenView] = React.useState(false);
    const [log, setLog] = React.useState('1');
    const [search, setSearch] = React.useState('2');
    const [copilot, setCopilot] = React.useState('3');
    const [aitext, setAitext] = React.useState('');
    const [aiquestion, setAiquestion] = React.useState('');
    const [aianswer, setAianswer] = React.useState('');
    const [aitextCopy, setAitextCopy] = React.useState('');
    const [prompt, setPrompt] = React.useState('');
    const [rating, setRating] = React.useState(0);
    const [level, setLevel] = React.useState('select');
    const [items, setItems] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [uuid, setUuid] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const history = useNavigate();
    const [categoryErrorText, setCategoryErrorText] = React.useState("");
    const [dialogText, setDialogText] = React.useState("");
    const [category, setCategory] = React.useState('select');
    const formOptions = {resolver: yupResolver(validationSchema)};
    const {register, handleSubmit, formState} = useForm(formOptions);
    const {errors} = formState;
    const [format, setFormat] = React.useState('star');
    const [date, setDate] = React.useState(null);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [tags, setTags] = React.useState('');
    const [value, setValue] = React.useState('1');
    const [categorySearch, setCategorySearch] = React.useState("");
    const [ratingSearch, setRatingSearch] = React.useState("");
    const [showForm, setShowForm] = React.useState(false)
    const [editMode, setEditMode] = React.useState(false);
    const [jobTitle, setJobTitle] = React.useState([]);
    const [openAuto, setOpenAuto] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const [menuItems, setMenuItems] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogStripe, setOpenDialogStripe] = React.useState(false);
    const [showCircle, setShowCircle] = React.useState(false);
    const [data, setData] = React.useState("universe");
    const [model, setModel] = React.useState("chatgpt");
    const [openCircle, setOpenCircle] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [interviewAi, setInterviewAi] = React.useState('4');
    const {transcript, resetTranscript} = useSpeechRecognition();
    const {question, setQuestion} = React.useState('');
    const [values, setValues] = React.useState('');
    const [difficulty, setDifficulty] = React.useState('easy');
    const [checked, setChecked] = React.useState(false);

    const onChange = (_,value) => {
        setValues(value);
    };
    const clearSelected = () => {
        setValues('');
    };

    let tab = searchParams.get("tab");

    const searchButton = React.createRef();

    const exportText = () => {
        const blob = new Blob(['Question: ' , aitext,'\nYour Answer :', transcript], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'text.txt';
        link.click();
    };

    const onSubmit = (event) => {
        event.preventDefault();
        if (category == 'select') {
            setCategoryErrorText("please select one responsibility");
            setDialogText("please select one responsibility");
            setOpenDialog(true);
            return;
        }
        const data = {
            "Category": category,
            "TypeFormat": format,
            "Rating": rating,
            "InsertDate": date,
            "Tags": tags,
            "Details": aitextCopy,
            "UUID": uuid,
            "Email": localStorage.getItem('user')
        }
        fetch(url + 'put', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(res => {
                    setCategory("select");
                    setFormat("star");
                    setAitextCopy("");
                    setRating(0);
                    setTags("");
                    setDate(null);
                    setUuid('');
                    //setMenuItems([])
                    setCategoryErrorText("");
                    //setShowForm(false);
                    if (editMode) {
                        setSearch('2');
                        setValue('2');
                    }
                    setOpenDialog(true);
                    setDialogText("Work log record added successfully");
                    if (!editMode) {
                        setValue('1')
                    }
                    //setItems([]);
                }
            );
    };

    const onSubmitSearch = (event) => {
        event.preventDefault();
        const data = {
            "Category": categorySearch,
            "Rating": ratingSearch,
            "fromDate": fromDate,
            "toDate": toDate,
            "Tags": tags,
            "Email": localStorage.getItem('user')
        }
        fetch(url + 'get', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(res => {
                    setShowModal(true)
                    setItems(res.items)
                }
            );
    };

    const feedback = () => {
        setShowCircle(true);
        setOpenCircle(true);
        const data = {
            "question": aitext,
            "answer": transcript,
        }
        fetch(url + 'getInterviewFeedback', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(res => {
                setShowCircle(false);
                setOpenCircle(false);
                setOpenDialog(true);
                setDialogText(res.response);
                }
            );
    };


    const handleCloseDialog = () => {
        setOpenDialog(false);
        if (editMode) {
            setEditMode(false);
            searchButton.current.click();
        }
    };

    const handleCloseDialogStripe = () => {
        setOpenDialog(false);
        if (editMode) {
            setEditMode(false);
            searchButton.current.click();
        }
        if (value == '3' || value == '4') {
            setLog('1')
            setValue('1')
            setOpenCircle(false)
        }
    };

    const handleChange = (event) => {
        setCategory(event.target.value);
        fetchAPIForDefault(event.target.value);
    };

    const handleChangeSearch = (event) => {
        setCategorySearch(event.target.value);
    };

    const handleChangeRating = (event) => {
        setRating(event.target.value);
    };

    const handleChangeLevel = (event) => {
        setLevel(event.target.value);
    };

    const handleChangeRatingSearch = (event) => {
        setRatingSearch(event.target.value);
    };

    const handleChangeDetails = (event) => {
        setAitextCopy(event.target.value);
    };

    const handleChangeFormat = (event) => {
        setFormat(event.target.value);
    }
    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
        resetTranscript();
        resetAll()
    };
    const handleChangeValueDifficulty = (event) => {
        setDifficulty(event.target.value)
    };
    const handleChangeData = (event) => {
        setData(event.target.value)
    };
    const handleChangeModel = (event) => {
        setModel(event.target.value)
    };

    const   handleChangeSelectFromMock = (event, newValue) => {
        let jobTitle = newValue ? newValue.jobTitle : ''
        setSearchText(jobTitle);
    };

    const handleChangeWorkLog = (event) => {
        setChecked(!checked);
    };

    const handleChangeSelect = (event, newValue) => {
        setShowCircle(true);
        setOpenCircle(true);
        const data = {
            "JobName": newValue,
        }
        fetch(url + 'getJobCategory', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(res => {
                    let values = res['items'].map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                    ));
                    setMenuItems(values);
                    setSearchText("");
                    setOptions([]);
                    setShowForm(true);
                    setShowCircle(false);
                    setOpenCircle(false);
                }
            );

    };

    const reset = (event) => {
        setCategory("select");
        setFormat("star");
        setCategorySearch("");
        setAitextCopy("");
        setRatingSearch("");
        setTags("");
        setDate(null);
        setUuid('');
        setItems([]);
        setCategoryErrorText("");
        setFile(null);
    };

    const resetAll = (event) => {
        setAitext('');
        setFile(null);
        setSearchText("");
        setOptions([]);
        setLevel('select')
        setValues('');
    };

    useEffect((e) => {
        resetTranscript();
        //resetAll(e)
        if (tab == '3') {
            //resetAll(e)
            setCopilot('3')
            setValue('3')
        }
        let token = localStorage.getItem('user');
        if (token == null) {
            history('/login');

        }// this will fire on every change :(
    }, []);

    // // On file select (from the pop up)
    // const onFileChange = (event) => {
    //     // Update the state
    //     setFile(event.target.files[0])
    // };

    const setFileToUpload = (event) => {
        setFile(event.target.files[0])
    }

    // Setting the logic for the asynchronous function on page reload
    React.useEffect(() => {
        let active = true;
        const timer = setTimeout(() => {
            (async () => {

                if (active && searchText.length > 3) {
                    setLoading(true);
                    const data = {
                        "Search": searchText,
                    }
                    fetch(url + 'getJobTitle', {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    })
                        .then(res => res.json())
                        .then(res => {
                                setOptions([...res.items]);
                                setLoading(false);
                            }
                        );

                }

            })();
        }, 500)

        return () => {
            active = false;
            clearTimeout(timer)
        };
    }, [searchText]);

    const fetchAPI = (param) => {
        setShowCircle(true);
        setOpenCircle(true);
        return fetch(url + 'getWorkSummary/' + param)
            .then(response => response.json())
            .then(jsonData => {
                setShowCircle(false);
                setOpenCircle(false);
                return jsonData.response;
            })
    }

    const fetchAPIForSub = () => {
        setShowCircle(true);
        setOpenCircle(true);
        let date = localStorage.getItem('date');
        if (date == null) {
            setOpenDialog(true);
        } else {
            setShowCircle(false);
            setOpenCircle(false);
        }
    }

    const startRecognition = () => {
        ReactSpeechRecognition.startListening({continuous: true})
    };

    const stopRecognition = () => {
        ReactSpeechRecognition.stopListening()
    };


    const fetchAPIMock = (param) => {
        setShowCircle(true);
        setOpenCircle(true);
        setAitext("");
        const formData = new FormData();
        let paramR = 'Give me a random ' ;
        if(level != 'select' && searchText != '' && difficulty != '') {
            paramR += ' technical question for ' + searchText + ' ' + ' level ' + level + ' ' + ' difficulty ' + difficulty
        }
        if(!checked) {
            formData.append('file', file)
            paramR += ' technical question from resume';
        }
        if(checked) {
            paramR += ' question from work experience';
        }
        formData.append('Email', localStorage.getItem('user'))
        formData.append('data', data)
        formData.append('model', model)
        formData.append('include', checked)
        formData.append('date', localStorage.getItem('date'))
        return fetch(url + 'getMockWithResume/' + paramR, {
            method: 'POST',
            headers: {},
            body: formData,
        })
            .then(response => response.json())
            .then(jsonData => {
                setShowCircle(false);
                setOpenCircle(false);
                if (jsonData.response === 'overlimit') {
                    setOpenDialogStripe(true);
                    return jsonData.response;
                } else {
                    return jsonData.response;
                }
            })
    }

    const fetchAPIForDefault = (categoryVal) => {
        setShowCircle(true);
        setOpenCircle(true);
        let param = "Give my job performance in STAR format " + categoryVal;
        fetch(url + 'getWorkSummary/' + param)
            .then(response => response.json())
            .then(jsonData => {
                setAitextCopy(jsonData.response);
                setShowCircle(false);
                setOpenCircle(false);
            })
    }

    function deleteAnItem(uuidFromDel) {
        return fetch(url + 'delete/' + uuidFromDel)
            .then(response => response.json())
            .then(jsonData => {
                let filteredArray = items.filter(item => item.UUID !== uuidFromDel)
                setItems(filteredArray);
                setUuid('');
            })
    }

    return (
        <div className="fancy-feature-ten pt-100 md-pt-70" id="features">
            <Helmet>
                <title>
                    Main Page || Log4u - Log your work
                </title>
            </Helmet>
            {/* End Page SEO Content */}
            <Header></Header>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Log4U"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{width: '100%', typography: 'body1', bgcolor: '#f8fafe'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChangeValue} aria-label="lab API tabs example">
                            <Tab label="Log" value={log}/>
                            <Tab label="Search" value={search}/>
                            <Tab label="Mock Interview AI" value={copilot}/>
                            <Tab label="Copilot Interview AI" value={interviewAi}/>
                        </TabList>
                    </Box>

                    <TabPanel value='1'>
                        <form
                            action="#"
                            className="subscription-form"
                            onSubmit={onSubmit}
                        >
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    {!editMode ?
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                id="asynchronous-demo"
                                                sx={{width: 600}}
                                                open={openAuto}
                                                onOpen={() => {
                                                    setOpenAuto(true);
                                                }}
                                                onClose={() => {
                                                    setOpenAuto(false);
                                                }}
                                                getOptionLabel={(option) => option.jobTitle}
                                                onChange={handleChangeSelect}
                                                options={options}
                                                loading={loading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Start type job title"
                                                        value={searchText}
                                                        onChange={(e) => setSearchText(e.target.value)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loading ? <CircularProgress color="inherit"
                                                                                                 size={20}/> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        : null}
                                </Grid>

                                {showCircle ?
                                    <Backdrop
                                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                        open={openCircle}
                                        onClick={handleClose}
                                    >
                                        <CircularProgress style={{margin: '10px'}}/>
                                    </Backdrop>
                                    : null
                                }

                                {showForm ?
                                    <Grid container spacing={3} style={{marginTop: '10px'}}>
                                        <Grid item xs={12}>
                                            <InputLabel id="demo-simple-select-label">Responsibility</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={category}
                                                label="Responsibility"
                                                onChange={handleChange}
                                                sx={{minWidth: "100%"}}
                                                error={!!categoryErrorText}
                                                helperText={categoryErrorText}
                                                disabled={editMode}
                                            >
                                                <MenuItem value={"select"}>Select one based on your job title</MenuItem>
                                                {menuItems}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel id="demo-simple-select-label">Format</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={format}
                                                label="Format"
                                                onChange={handleChangeFormat}
                                                sx={{minWidth: "100%"}}
                                            >
                                                <MenuItem value={"star"}>STAR</MenuItem>
                                                <MenuItem value={"free"}>Free Text</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <InputLabel id="demo-simple-select-label">Rating</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={rating}
                                                label="Rating"
                                                onChange={handleChangeRating}
                                                sx={{minWidth: "100%"}}
                                            >
                                                <MenuItem value={"0"}>0</MenuItem>
                                                <MenuItem value={"1"}>1</MenuItem>
                                                <MenuItem value={"2"}>2</MenuItem>
                                                <MenuItem value={"3"}>3</MenuItem>
                                                <MenuItem value={"4"}>4</MenuItem>
                                                <MenuItem value={"5"}>5</MenuItem>
                                            </Select>
                                        </Grid>

                                        <Grid item xs={11}>
                                            <TextareaAutosize
                                                maxRows={100}
                                                minRows={10}
                                                placeholder="Enter Details"
                                                defaultValue=''
                                                value={aitextCopy}
                                                style={{width: "100%"}}
                                                onChange={handleChangeDetails}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <div style={{paddingTop: "100px"}}>
                                                {/*<Button onClick={handleOpen}>Ask AI</Button>*/}
                                                <Modal
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                        <Grid item sm={12} style={{marginBottom: '10px'}}>
                                                            <TextField
                                                                id="askai"
                                                                name="askai"
                                                                label="Prompt"
                                                                value={prompt}
                                                                fullWidth
                                                                variant="outlined"
                                                                onChange={(e) => setPrompt(e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12}>
                                                            <Button variant="outlined"
                                                                    onClick={() => {
                                                                        fetchAPI(prompt).then(result => setAitext(result));
                                                                    }}
                                                            >
                                                                Response
                                                            </Button>
                                                        </Grid>
                                                        {showCircle ?
                                                            <Backdrop
                                                                sx={{
                                                                    color: '#fff',
                                                                    zIndex: (theme) => theme.zIndex.drawer + 1
                                                                }}
                                                                open={openCircle}
                                                                onClick={handleClose}
                                                            >
                                                                <CircularProgress style={{margin: '10px'}}/>
                                                            </Backdrop>
                                                            : null
                                                        }

                                                        <Grid item sm={12}>
                                                            <TextareaAutosize
                                                                maxRows={25}
                                                                minRows={10}
                                                                placeholder=""
                                                                defaultValue=''
                                                                value={aitext}
                                                                style={{width: "100%"}}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12}>
                                                            <Button variant="outlined"
                                                                    onClick={() => {
                                                                        setOpen(false);
                                                                        setAitextCopy(aitext);
                                                                    }}
                                                            >
                                                                Copy and Paste
                                                            </Button>
                                                        </Grid>
                                                    </Box>
                                                </Modal>
                                            </div>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker value={date} defaultValue={date}
                                                                onChange={(value) => setDate(value)} label="Enter Date"
                                                                fullWidth/>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>


                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                id="tags"
                                                name="tags"
                                                label="Tag(s)"
                                                fullWidth
                                                variant="outlined"
                                                value={tags}
                                                onChange={(e) => setTags(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Button type="submit" fullWidth variant="contained" color="primary">
                                                Submit
                                            </Button>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Button variant="outlined" onClick={(e) => reset(e)}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    : null}

                            </React.Fragment>
                        </form>
                    </TabPanel>
                    <TabPanel value='2'>
                        <form
                            action="#"
                            className="subscription-form"
                            onSubmit={onSubmitSearch}
                        >
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    <Grid item sm={12}>
                                        <InputLabel id="demo-simple-select-label">By Rating</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={ratingSearch}
                                            label="By Rating"
                                            onChange={handleChangeRatingSearch}
                                            sx={{minWidth: "100%"}}
                                            displayEmpty
                                        >
                                            <MenuItem value={""} default>Select a rating</MenuItem>
                                            <MenuItem value={"0"}>0</MenuItem>
                                            <MenuItem value={"1"}>1</MenuItem>
                                            <MenuItem value={"2"}>2</MenuItem>
                                            <MenuItem value={"3"}>3</MenuItem>
                                            <MenuItem value={"4"}>4</MenuItem>
                                            <MenuItem value={"5"}>5</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker value={fromDate} defaultValue={date}
                                                            onChange={(value) => setFromDate(value)} label="From Date"
                                                            fullWidth/>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker value={toDate} defaultValue={date}
                                                            onChange={(value) => setToDate(value)} label="To Date"
                                                            fullWidth/>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="tags"
                                            name="tags"
                                            label="By Tag(s)"
                                            fullWidth
                                            variant="outlined"
                                            value={tags}
                                            onChange={(e) => setTags(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item sm={1}>
                                        <Button type="submit" fullWidth variant="contained" color="primary"
                                                ref={searchButton}>
                                            Search
                                        </Button>
                                    </Grid>


                                    <Grid item sm={1}>
                                        <Button variant="outlined"
                                                onClick={(e) => reset(e)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    {items && items.length > 0 &&
                                        <Grid item xs={12} sm={12}>
                                            <div>
                                                <Modal
                                                    open={openView}
                                                    onClose={handleClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                        <TableContainer component={Paper}>
                                                            <Table>
                                                                <TableRow>
                                                                    <TableCell>Category</TableCell>
                                                                    <TableCell align="right">{category}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Rating</TableCell>
                                                                    <TableCell align="right">{rating}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Format</TableCell>
                                                                    <TableCell align="right">{format}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Details</TableCell>
                                                                    <TableCell align="left">{aitextCopy}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Date</TableCell>
                                                                    <TableCell align="right">{date}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>Tags</TableCell>
                                                                    <TableCell align="right">{tags}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell> <Button variant="outlined"
                                                                                        onClick={() => {
                                                                                            setOpenView(false);
                                                                                        }}
                                                                    >
                                                                        Done
                                                                    </Button></TableCell>
                                                                </TableRow>
                                                            </Table>
                                                        </TableContainer>

                                                    </Box>
                                                </Modal>
                                                <DataGrid rows={items} getRowId={(row) => row.UUID} pageSize={10}
                                                          radioSelection columns={[{
                                                    field: 'UUID', width: 200,
                                                    disableClickEventBubbling: true,
                                                }, {field: 'Email', width: 200}, {field: 'Category'}, {
                                                    field: 'TypeFormat',
                                                    width: 100
                                                },
                                                    {field: 'Details', width: 200}, {field: 'Rating'}, {
                                                        field: 'InsertDate',
                                                        width: 200
                                                    }, {field: 'Tags'},
                                                    {
                                                        field: 'action',
                                                        headerName: 'Action',
                                                        width: 360,
                                                        sortable: false,
                                                        disableClickEventBubbling: true,

                                                        renderCell: (params) => {
                                                            const onClick = (e) => {
                                                                const currentRow = params.row;
                                                                return alert(JSON.stringify(currentRow, null, 4));
                                                            };
                                                            const onClickEdit = (e) => {
                                                                const currentRow = params.row;
                                                                setUuid(params.row.UUID)
                                                                setCategory(params.row.Category)
                                                                setRating(params.row.Rating)
                                                                setDate(dayjs(params.row.InsertDate, 'YYYY-MM-DD HH:mm:ss'))
                                                                setAitextCopy(params.row.Details)
                                                                setTags(params.row.Tags)
                                                                setFormat(params.row.TypeFormat)
                                                                setEditMode(true)
                                                                setShowForm(true)
                                                                setLog('1');
                                                                setValue('1')
                                                                // return alert(JSON.stringify(currentRow, null, 4));
                                                            };

                                                            const onClickView = (e) => {
                                                                const currentRow = params.row;
                                                                setCategory(params.row.Category)
                                                                setRating(params.row.Rating)
                                                                setDate(dayjs(params.row.InsertDate, 'YYYY-MM-DD HH:mm:ss').toString())
                                                                setAitextCopy(params.row.Details)
                                                                setTags(params.row.Tags)
                                                                setFormat(params.row.TypeFormat)
                                                                setOpenView(true)
                                                            };

                                                            const onClickDelete = (e) => {
                                                                const currentRow = params.row;
                                                                setUuid(params.row.UUID)
                                                                deleteAnItem(params.row.UUID)
                                                                // return alert(JSON.stringify(currentRow, null, 4));
                                                            };

                                                            return (
                                                                <Stack direction="row" spacing={2}>
                                                                    <Button variant="outlined" color="warning"
                                                                            size="small"
                                                                            onClick={onClickEdit}>Edit</Button>
                                                                    <Button variant="outlined" color="warning"
                                                                            size="small"
                                                                            onClick={onClickDelete}>Delete</Button>
                                                                    <Button variant="outlined" color="warning"
                                                                            size="small"
                                                                            onClick={onClickView}>View</Button>
                                                                </Stack>
                                                            );
                                                        },
                                                    }]}/>

                                            </div>
                                        </Grid>
                                    }
                                </Grid>
                            </React.Fragment>
                        </form>
                    </TabPanel>
                    <TabPanel value='3'>
                        <form
                            action="#"
                            className="subscription-form"
                            onSubmit={onSubmit}
                        >
                            <Dialog
                                open={openDialogStripe}
                                onClose={handleCloseDialogStripe}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Log4U"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        You don't have premium access. To buy premium access for $2.99 per month(no auto
                                        bill), please use the following payment.
                                        <Stripe></Stripe>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDialog} autoFocus>
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <React.Fragment>
                                <Grid item sm={12} style={{marginBottom: '5px'}}>

                                    {showCircle ?
                                        <Backdrop
                                            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                            open={openCircle}
                                            onClick={handleClose}
                                        >
                                            <CircularProgress style={{margin: '10px'}}/>
                                        </Backdrop>
                                        : null
                                    }
                                    <Grid item sm={12} style={{marginTop: "25px"}}>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="chatgpt"
                                            name="radio-buttons-group"
                                            onChange={handleChangeModel}
                                            value={model}
                                        >
                                            <FormControlLabel value="chatgpt" control={<Radio/>}
                                                              label="Chat-GPT - Premium $2.99 for a month after 6 calls"/>
                                            <FormControlLabel value="gemini" control={<Radio/>}
                                                              label="Gemini AI- Free Unlimited"/>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>

                                        <Grid container sm = {10} gutter={16} justify='center' direction="row" style={{marginTop: "25px"}}>
                                            <Grid item sm={2} style={{marginBottom:1}}>
                                            <Autocomplete
                                                            id="asynchronous-demo"
                                                            sx={{ width: 250 }}
                                                            open={openAuto}
                                                            value={values}
                                                            onOpen={() => {
                                                                setOpenAuto(true);
                                                            }}
                                                            onClose={() => {
                                                                setOpenAuto(false);
                                                            }}
                                                            getOptionLabel={(option) => option ? option.jobTitle : ''}
                                                            onChange={onChange}
                                                            options={options}
                                                            loading={loading}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Start type job title"
                                                                    value={searchText}
                                                                    onChange={(e) => setSearchText(e.target.value)}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                            </Grid>
                                            <Grid item sm={2} style={{marginBottom:1}}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={level}
                                                            label="Level"
                                                            sx={{ width: 250 }}
                                                            onChange={handleChangeLevel}
                                                        >
                                                            <MenuItem value={"select"}>select level</MenuItem>
                                                            <MenuItem value={"intern"}>Intern</MenuItem>
                                                            <MenuItem value={"junior"}>Junior</MenuItem>
                                                            <MenuItem value={"middle"}>Middle</MenuItem>
                                                            <MenuItem value={"senior"}>Senior</MenuItem>
                                                            <MenuItem value={"lead"}>Lead</MenuItem>
                                                            <MenuItem value={"manager"}>Manager</MenuItem>
                                                            <MenuItem value={"directot"}>Director</MenuItem>
                                                            <MenuItem value={"vp"}>VP</MenuItem>
                                                        </Select>
                                            </Grid>
                                            <Grid item sm={2} style={{marginBottom:1}}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={difficulty}
                                                    label="Level"
                                                    sx={{ width: 250 }}
                                                    onChange={handleChangeValueDifficulty}
                                                >
                                                    <MenuItem value={"easy"}>Easy</MenuItem>
                                                    <MenuItem value={"medium"}>Medium</MenuItem>
                                                    <MenuItem value={"hard"}>Hard</MenuItem>

                                                </Select>
                                            </Grid>
                                        </Grid>
                                            <Grid container={3} style={{marginTop:'5px'}}>
                                                <Grid item sm={1} style={{marginTop:'10px'}}>
                                                <label htmlFor="filePicker">
                                                    Resume <FaUpload size={25} style={{marginLeft:"5px",color: "green",
                                                    cursor: "pointer"}}/>
                                                </label>
                                                <p style={{fontSize:10}}> {file ? file.name : ''}</p>
                                                <input id="filePicker" style={{visibility:"hidden"}} type={"file"} onChange={setFileToUpload}/>
                                                </Grid>
                                                <p style={{fontSize:10, marginTop:'5px'}}> OR </p>
                                                <Grid item sm={2}>
                                                            <Checkbox checked={checked}
                                                            onChange={handleChangeWorkLog}></Checkbox>
                                                    Include Work Log
                                                </Grid>
                                            </Grid>
                                <Grid container sm={8} gutter={1} justify='center' direction="row" style={{marginTop: "5px"}}>
                                            <Grid item sm={1} style={{marginBottom:1,marginTop:5}}>
                                                <label htmlFor="filePicker">
                                                    Start
                                                </label>
                                                {(searchText != '' && level != 'select') || (file != null || checked) ? (
                                                <FaPlay size={25} style={{
                                                    marginLeft:"10px",
                                                    color: "green",
                                                    cursor: "pointer"}}
                                                        onClick={() => { resetTranscript();fetchAPIMock("Give me one random question").then(result => setAitext(result))}}/>
                                                ) :
                                                    <FaPlay size={25} style={{
                                                        marginLeft:"10px",
                                                        color: "red"}}/>
                                                }
                                            </Grid>
                                            <Grid item sm={1} style={{marginBottom:1,marginTop:5}}>
                                                <label htmlFor="filePicker">
                                                    Answer
                                                </label>
                                                {aitext == '' ? <FaMicrophone size={25} style={{marginLeft:"10px"}} onClick={startRecognition}/> :
                                                    <FaMicrophone size={25} style={{marginLeft:"10px",color: "green",
                                                        cursor: "pointer"}} onClick={startRecognition}/>}

                                            </Grid>
                                            <Grid item sm={1} style={{marginBottom:1,marginTop:5}}>
                                                <label htmlFor="filePicker">
                                                    Stop
                                                </label>
                                                {transcript != '' ? <FaStop size={25} style={{marginLeft:"10px",color: "red",
                                                    cursor: "pointer"}} onClick={stopRecognition}/> : <FaStop size={25} style={{marginLeft:"10px"}} onClick={stopRecognition}/>}

                                            </Grid>
                                            <Grid item sm={1} style={{marginBottom:1,marginTop:5}}>
                                                <label htmlFor="filePicker">
                                                    Reset
                                                </label>
                                                <FaSync size={25} style={{marginLeft:"10px",color: "orange",
                                                    cursor: "pointer"}} onClick={() => {
                                                    resetTranscript();
                                                    resetAll();}}/>

                                            </Grid>
                                        <Grid item sm={1} style={{marginBottom:1,marginTop:5}}>
                                            <label htmlFor="filePicker">
                                                Export
                                            </label>
                                            {aitext != '' ?
                                            <FaFileExport size={25} style={{marginLeft:"10px",color: "green",
                                                cursor: "pointer"}} onClick={() => {
                                                exportText();
                                            }}/>
                                                :
                                                <FaFileExport size={25} style={{marginLeft:"10px"}} />
                                            }
                                        </Grid>
                                        <Grid item sm={1} style={{marginBottom:1,marginTop:5}}>
                                            <label htmlFor="filePicker">
                                                Feedback
                                            </label>
                                            {transcript != '' ?
                                                <FaComments size={25} style={{marginLeft: "10px",color: "green",
                                                    cursor: "pointer"}} onClick={() => {
                                                    feedback();
                                                }}/> :
                                                <FaComments size={25} style={{marginLeft: "10px"}}/>
                                            }
                                        </Grid>
                                        </Grid>
                                <p style={{fontSize:10}}> Hint : Select job title and level OR upload resume(doc or pdf format) OR include work logs then click start</p>
                                        <Grid item sm={12} style={{marginTop: "1px"}}>
                                            <TextareaAutosize
                                                maxRows={10}
                                                minRows={5}
                                                placeholder=""
                                                defaultValue=''
                                                value={aitext.substring(aitext.indexOf(':')+1, aitext.length)}
                                                style={{width: "100%"}}
                                            />
                                            <TextareaAutosize
                                                maxRows={10}
                                                minRows={5}
                                                placeholder=""
                                                defaultValue=''
                                                value={transcript}
                                                style={{width: "100%"}}
                                            />
                                        </Grid>
                            </React.Fragment>
                        </form>
                    </TabPanel>
                    <TabPanel value='4'>
                        <Copilot></Copilot>
                    </TabPanel>
                </TabContext>
            </Box>

            {/*
     =============================================
				Fancy Feature One
		============================================== */}
            {/*<div className="fancy-feature-one pb-150 md-pb-80">*/}
            {/*<div className="text-center">*/}
            {/*<div className="feature-img-area">*/}
            {/*<img src="images/assets/feature-img-01.png" alt="feature" />*/}
            {/*<img*/}
            {/*  src="images/assets/feature-img-01-01.png"*/}
            {/*  alt="feature"*/}
            {/*  className="cs-screen screen-one"*/}
            {/*/>*/}
            {/*<img*/}
            {/*  src="images/assets/feature-img-01-02.png"*/}
            {/*  alt="feature"*/}
            {/*  className="cs-screen screen-two"*/}
            {/*  data-aos="fade-left"*/}
            {/*  data-aos-duration="1200"*/}
            {/*/>*/}
            {/*<img*/}
            {/*  src="images/assets/feature-img-01-03.png"*/}
            {/*  alt="feature"*/}
            {/*  className="cs-screen screen-three"*/}
            {/*  data-aos="fade-right"*/}
            {/*  data-aos-duration="1200"*/}
            {/*  data-aos-delay="100"*/}
            {/*/>*/}
            {/*<img*/}
            {/*  src="images/assets/feature-img-01-04.png"*/}
            {/*  alt="feature"*/}
            {/*  className="cs-screen screen-four"*/}
            {/*  data-aos="fade-right"*/}
            {/*  data-aos-duration="1200"*/}
            {/*  data-aos-delay="150"*/}
            {/*/>*/}
            {/*<img*/}
            {/*  src="images/assets/feature-img-01-05.png"*/}
            {/*  alt="feature"*/}
            {/*  className="cs-screen screen-five"*/}
            {/*  data-aos="fade-left"*/}
            {/*  data-aos-duration="1200"*/}
            {/*  data-aos-delay="200"*/}
            {/*/>*/}
            {/*</div>*/}
            {/* /.feature-img-area */}
            {/*</div>*/}
            {/* /.text-center */}

            {/*
        =============================================
				Fancy Block Style One
		    ============================================== */}
            {/*  <div className="block-style-one">*/}
            {/*    <div className="container">*/}
            {/*      <div className="d-lg-flex align-items-center justify-content-between inner-container">*/}
            {/*        <BlockMetaData />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    /!* /.container *!/*/}
            {/*  </div>*/}
            {/*  /!* /.block-style-one *!/*/}
            {/*</div>*/}
            {/* /.fancy-feature-one */}

            {/*
     =============================================
				Fancy Feature Two
		============================================== */}
            {/*<div className="fancy-feature-two">*/}
            {/*  <div className="container">*/}
            {/*    <div className="row">*/}
            {/*      <div className="col-xl-7 col-lg-7 col-md-9 m-auto">*/}
            {/*        <div className="title-style-one text-center mb-190 md-mb-100">*/}
            {/*          <h2>Know everything about App features.</h2>*/}
            {/*        </div>*/}
            {/*        /!* /.title-style-one *!/*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <div className="container">*/}
            {/*    <div className="row align-items-center block-style-two pb-250 md-pb-90">*/}
            {/*      <div className="col-lg-7 col-md-8 m-auto">*/}
            {/*        <div data-aos="fade-right" data-aos-duration="1200">*/}
            {/*          <div className="img-holder img-holder-one">*/}
            {/*            <div*/}
            {/*              className="cs-screen progress-line-one"*/}
            {/*              data-aos="fade-right"*/}
            {/*              data-aos-duration="1200"*/}
            {/*            ></div>*/}
            {/*            <div*/}
            {/*              className="cs-screen progress-line-two"*/}
            {/*              data-aos="fade-right"*/}
            {/*              data-aos-duration="1200"*/}
            {/*            ></div>*/}
            {/*            <img src="images/assets/feature-img-05.png" alt="feature" />*/}
            {/*            <img*/}
            {/*              src="images/shape/1.svg"*/}
            {/*              alt="feature"*/}
            {/*              className="cs-screen screen-one"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*              src="images/shape/2.svg"*/}
            {/*              alt="feature"*/}
            {/*              className="cs-screen dot-shape"*/}
            {/*            />*/}
            {/*            <div className="bubble-one"></div>*/}
            {/*            <div className="bubble-two"></div>*/}
            {/*            <div className="bubble-three"></div>*/}
            {/*            <div className="bubble-four"></div>*/}
            {/*          </div>*/}
            {/*          /!* /.img-holder *!/*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      /!* End .col *!/*/}
            {/*      <div className="col-lg-5">*/}
            {/*        <div*/}
            {/*          className="text-wrapper"*/}
            {/*          data-aos="fade-left"*/}
            {/*          data-aos-duration="1200"*/}
            {/*        >*/}
            {/*          <h6 className="font-rubik" style={{ color: "#FF5291" }}>*/}
            {/*            FLEXIBILITY*/}
            {/*          </h6>*/}
            {/*          <h3 className="font-gilroy-bold">*/}
            {/*            Get instant visibility into all your team work.*/}
            {/*          </h3>*/}
            {/*          <p>*/}
            {/*            Don’t waste time on tedious manual tasks. Let Automation do it*/}
            {/*            for you. Simplify workflows, reduce errors, and save time for*/}
            {/*            solving more important problems.*/}
            {/*          </p>*/}
            {/*          <a href="#">Learn about flexibility</a>*/}
            {/*        </div>*/}
            {/*        /!*  /.text-wrapper *!/*/}
            {/*      </div>{" "}*/}
            {/*      /!* End .col *!/*/}
            {/*    </div>*/}
            {/*    /!* /.block-style-two *!/*/}

            {/*    <div className="row align-items-center block-style-two pb-250 md-pb-90">*/}
            {/*      <div className="col-lg-6 col-md-8 m-auto order-lg-last">*/}
            {/*        <div data-aos="fade-left" data-aos-duration="1200">*/}
            {/*          <div className="img-holder img-holder-two">*/}
            {/*            <img src="images/assets/feature-img-06.png" alt="feature" />*/}
            {/*            <img*/}
            {/*              src="images/assets/feature-img-06-01.png"*/}
            {/*              alt="feature"*/}
            {/*              className="cs-screen screen-one"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*              src="images/shape/3.svg"*/}
            {/*              alt="feature"*/}
            {/*              className="cs-screen screen-two"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*              src="images/shape/4.svg"*/}
            {/*              alt="feature"*/}
            {/*              className="cs-screen dot-shape"*/}
            {/*            />*/}
            {/*            <div className="bubble-one"></div>*/}
            {/*            <div className="bubble-two"></div>*/}
            {/*            <div className="bubble-three"></div>*/}
            {/*            <div className="bubble-four"></div>*/}
            {/*          </div>*/}
            {/*          /!* /.img-holder *!/*/}
            {/*        </div>*/}
            {/*      </div>{" "}*/}
            {/*      /!* End .col *!/*/}
            {/*      <div className="col-lg-6 order-lg-first">*/}
            {/*        <div*/}
            {/*          className="text-wrapper ps-5 pe-5"*/}
            {/*          data-aos="fade-right"*/}
            {/*          data-aos-duration="1200"*/}
            {/*        >*/}
            {/*          <h6 className="font-rubik" style={{ color: "#AA4CFF" }}>*/}
            {/*            QUICK & FAST*/}
            {/*          </h6>*/}
            {/*          <h3 className="font-gilroy-bold">Fast App response time</h3>*/}
            {/*          <p>*/}
            {/*            Log4u  is 5x as fast to load than other popular tools, quick to*/}
            {/*            configure.*/}
            {/*          </p>*/}
            {/*          <div className="quote">*/}
            {/*            <blockquote>*/}
            {/*              “Our team really feel great to use the Log4u  app specially*/}
            {/*              their faster load & server speed”*/}
            {/*            </blockquote>*/}
            {/*            <div className="d-flex align-items-center">*/}
            {/*              <img src="images/media/img_01.png" alt="media" />*/}
            {/*              <div className="info-meta">*/}
            {/*                <h5>Micle Duke, Product Manager</h5>*/}
            {/*                <span className="font-rubik">Uber Inc.</span>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*          /!*  /.quote *!/*/}
            {/*        </div>*/}
            {/*        /!*  /.text-wrapper *!/*/}
            {/*      </div>{" "}*/}
            {/*      /!* End .col *!/*/}
            {/*    </div>*/}
            {/*    /!* /.block-style-two *!/*/}

            {/*    <div className="row align-items-center block-style-two pb-170 md-pb-50">*/}
            {/*      <div className="col-lg-7 col-md-7 m-auto">*/}
            {/*        <div data-aos="fade-right" data-aos-duration="1200">*/}
            {/*          <div className="img-holder img-holder-three">*/}
            {/*            <img src="images/assets/feature-img-07.png" alt="feature" />*/}
            {/*            <img*/}
            {/*              src="images/assets/feature-img-07-01.png"*/}
            {/*              alt="feature"*/}
            {/*              className="cs-screen screen-one"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*              src="images/shape/5.svg"*/}
            {/*              alt="feature"*/}
            {/*              className="cs-screen screen-two"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*              src="images/shape/6.svg"*/}
            {/*              alt="feature"*/}
            {/*              className="cs-screen dot-shape"*/}
            {/*            />*/}
            {/*            <div className="bubble-one"></div>*/}
            {/*            <div className="bubble-two"></div>*/}
            {/*            <div className="bubble-three"></div>*/}
            {/*            <div className="bubble-four"></div>*/}
            {/*          </div>*/}
            {/*          /!* /.img-holder *!/*/}
            {/*        </div>*/}
            {/*      </div>{" "}*/}
            {/*      /!* End .col *!/*/}
            {/*      <div className="col-lg-5">*/}
            {/*        <div*/}
            {/*          className="text-wrapper"*/}
            {/*          data-aos="fade-left"*/}
            {/*          data-aos-duration="1200"*/}
            {/*        >*/}
            {/*          <h6 className="font-rubik" style={{ color: "#03DDD5" }}>*/}
            {/*            PROJECT STATUS*/}
            {/*          </h6>*/}
            {/*          <h3 className="font-gilroy-bold">*/}
            {/*            Real time project status monitoring.*/}
            {/*          </h3>*/}
            {/*          <p>*/}
            {/*            Don’t waste time on tedious manual tasks. Let Automation do it*/}
            {/*            for you. Simplify workflows, reduce errors, and save time for*/}
            {/*            solving more important problems.*/}
            {/*          </p>*/}
            {/*        </div>*/}
            {/*        /!* /.text-wrapper *!/*/}
            {/*      </div>{" "}*/}
            {/*      /!* End .col *!/*/}
            {/*    </div>*/}
            {/*    /!* /.block-style-two *!/*/}
            {/*  </div>*/}
            {/*  /!* /.container *!/*/}
            {/*</div>*/}
            {/* /.fancy-feature-two */}

            {/*
     =============================================
			Usable Tools Section
		============================================== */}
            {/* <div className="useable-tools-section bg-color mt-140 mb-50 md-mt-80">*/}
            {/*   <div className="bubble-one"></div>*/}
            {/*   <div className="bubble-two"></div>*/}
            {/*   <div className="bubble-three"></div>*/}
            {/*   <div className="bubble-four"></div>*/}
            {/*   <div className="bubble-five"></div>*/}
            {/*   <div className="container">*/}
            {/*     <h6 className="font-rubik">Integrates with your tools</h6>*/}
            {/*     <h2>*/}
            {/*       Log4u  ties into your existing tools, services, & workflow. Get*/}
            {/*       notifications or create a Story with others tools.*/}
            {/*     </h2>*/}

            {/*     <SocialTwo />*/}
            {/*   </div>*/}
            {/*   /!* /.container *!/*/}
            {/* </div>*/}
            {/* /!* /.useable-tools-section *!/*/}

            {/* /!* */}
            {/*=============================================*/}
            {/*		Fancy Feature Three*/}
            {/*============================================== *!/*/}
            {/* <div className="fancy-feature-three pt-200 md-pt-80">*/}
            {/*   <div className="container">*/}
            {/*     <div className="row">*/}
            {/*       <div className="col-lg-6">*/}
            {/*         <div className="title-style-one">*/}
            {/*           <h6 className="font-rubik">You’re in good company</h6>*/}
            {/*           <h2>Join the community by using our apps to grow faster.</h2>*/}
            {/*         </div>*/}
            {/*         /!* /.title-style-one *!/*/}

            {/*         <CounterOne />*/}
            {/*         /!* /.counter-info-classic *!/*/}
            {/*       </div>*/}

            {/*       <div className="col-lg-6">*/}
            {/*         <div className="clients-feedback-classic md-mt-80">*/}
            {/*           <div*/}
            {/*             className="feedback-wrapper"*/}
            {/*             data-aos="fade-up"*/}
            {/*             data-aos-duration="1200"*/}
            {/*           >*/}
            {/*             <img*/}
            {/*               src="images/media/img_02.png"*/}
            {/*               alt=""*/}
            {/*               className="media-meta"*/}
            {/*             />*/}
            {/*             <p>*/}
            {/*               it’s a great exprience to work with Log4u . They’r vey humble*/}
            {/*               and expert & the service has been excellent & super.*/}
            {/*             </p>*/}
            {/*             <h6 className="name">Rena Singleton</h6>*/}
            {/*             <span className="font-rubik disg-info">*/}
            {/*               Former Assistant Jamuna Group.*/}
            {/*             </span>*/}
            {/*           </div>{" "}*/}
            {/*           /!* /.feedback-wrapper *!/*/}
            {/*           <div*/}
            {/*             className="feedback-wrapper"*/}
            {/*             data-aos="fade-up"*/}
            {/*             data-aos-duration="1200"*/}
            {/*           >*/}
            {/*             <img*/}
            {/*               src="images/media/img_03.png"*/}
            {/*               alt=""*/}
            {/*               className="media-meta"*/}
            {/*             />*/}
            {/*             <p>*/}
            {/*               it’s a great exprience to work with Log4u . They’r vey humble*/}
            {/*               and expert & the service has been excellent & super.*/}
            {/*             </p>*/}
            {/*             <h6 className="name">Rashed Ka.</h6>*/}
            {/*             <span className="font-rubik disg-info">*/}
            {/*               UI designer Baper Inc.*/}
            {/*             </span>*/}
            {/*           </div>{" "}*/}
            {/*           /!* /.feedback-wrapper *!/*/}
            {/*           <div*/}
            {/*             className="feedback-wrapper"*/}
            {/*             data-aos="fade-up"*/}
            {/*             data-aos-duration="1200"*/}
            {/*           >*/}
            {/*             <img*/}
            {/*               src="images/media/img_04.png"*/}
            {/*               alt=""*/}
            {/*               className="media-meta"*/}
            {/*             />*/}
            {/*             <p>*/}
            {/*               it’s a great exprience to work with Log4u . They’r vey humble*/}
            {/*               and expert & the service has been excellent & super.*/}
            {/*             </p>*/}
            {/*             <h6 className="name">Zubayer Hasan</h6>*/}
            {/*             <span className="font-rubik disg-info">*/}
            {/*               Front-end coder HeloShape.*/}
            {/*             </span>*/}
            {/*           </div>*/}
            {/*           /!* /.feedback-wrapper *!/*/}
            {/*         </div>*/}
            {/*         /!* /.clients-feedback-classic *!/*/}
            {/*       </div>*/}
            {/*     </div>*/}
            {/*   </div>*/}
            {/* </div>*/}
            {/* /!* /.fancy-feature-three *!/*/}

            {/* /!* */}
            {/*=============================================*/}
            {/*	Call To Action*/}
            {/*============================================== *!/*/}
            {/* <div className="fancy-short-banner-one">*/}
            {/*   <div className="container">*/}
            {/*     <CallToActionFour />*/}
            {/*   </div>*/}
            {/*   /!* /.container *!/*/}
            {/*   <div className="bubble-one"></div>*/}
            {/*   <div className="bubble-two"></div>*/}
            {/*   <div className="bubble-three"></div>*/}
            {/*   <div className="bubble-four"></div>*/}
            {/*   <div className="bubble-five"></div>*/}
            {/*   <div className="bubble-six"></div>*/}
            {/* </div>*/}
            {/* /!* /.fancy-short-banner-one *!/*/}

            {/* /!* */}
            {/*=============================================*/}
            {/*		Faq Classic*/}
            {/*============================================== *!/*/}
            {/* <div className="faq-classic pt-225 md-pt-120">*/}
            {/*   <div className="container">*/}
            {/*     <div className="row">*/}
            {/*       <div className="col-lg-6">*/}
            {/*         <div className="title-style-one">*/}
            {/*           <h6 className="font-rubik">Find your answers</h6>*/}
            {/*           <h2>Have any thought? Look here.</h2>*/}
            {/*         </div>*/}
            {/*         /!* /.title-style-one *!/*/}
            {/*         <Link to="/faq" className="theme-btn-one mt-50 md-mt-30">*/}
            {/*           {" "}*/}
            {/*           Go to Faq*/}
            {/*         </Link>*/}
            {/*       </div>*/}
            {/*       /!* End .col *!/*/}
            {/*       <div className="col-lg-6">*/}
            {/*         <div className="md-mt-60">*/}
            {/*           <FaqClassic />*/}
            {/*         </div>*/}
            {/*       </div>*/}
            {/*       /!* End .col *!/*/}
            {/*     </div>*/}
            {/*   </div>*/}
            {/* </div>*/}
            {/* /!* /.faq-classic *!/*/}

            {/* /!* */}
            {/* =============================================*/}
            {/*		Footer*/}
            {/*  ============================================== *!/*/}
            {/* <div className="footer-bg-wrapper">*/}
            {/*   <div className="bubble-one"></div>*/}
            {/*   <div className="bubble-two"></div>*/}
            {/*   <div className="bubble-three"></div>*/}
            {/*   <div className="fancy-short-banner-two">*/}
            {/*     <div className="container">*/}
            {/*       <div className="content-wrapper">*/}
            {/*         <div*/}
            {/*           className="*/}
            {/*             bg-wrapper*/}
            {/*             d-lg-flex*/}
            {/*             align-items-center*/}
            {/*             justify-content-between*/}
            {/*           "*/}
            {/*         >*/}
            {/*           <h2 className="font-gilroy-bold">*/}
            {/*             Don’t find the answer? contact us for any query.*/}
            {/*           </h2>*/}
            {/*           <Link to="/contact-pm"> Contact us</Link>*/}
            {/*           <div className="bubble-one"></div>*/}
            {/*           <div className="bubble-two"></div>*/}
            {/*           <div className="bubble-three"></div>*/}
            {/*           <div className="bubble-four"></div>*/}
            {/*           <div className="bubble-five"></div>*/}
            {/*         </div>*/}
            {/*         /!* /.bg-wrapper *!/*/}
            {/*       </div>*/}
            {/*       /!*  /.content-wrapper *!/*/}
            {/*     </div>*/}
            {/*     /!* /.container *!/*/}
            {/*   </div>*/}
            {/*   /!* /.fancy-short-banner-two *!/*/}

            {/*   <footer className="theme-footer-one pt-130 md-pt-70">*/}
            {/*     <div className="top-footer">*/}
            {/*       <div className="container">*/}
            {/*         <Footer />*/}
            {/*       </div>*/}
            {/*       /!* /.container *!/*/}
            {/*     </div>*/}
            {/*     /!* /.top-footer *!/*/}

            {/*     <div className="container">*/}
            {/*       <div className="bottom-footer-content">*/}
            {/*         <CopyRight />*/}
            {/*       </div>*/}
            {/*       /!*  /.bottom-footer *!/*/}
            {/*     </div>*/}
            {/*   </footer>*/}
            {/*   /!* /.theme-footer-one *!/*/}
            {/* </div>*/}
        </div>
    );
};

export default ProjectManagement;
