import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>Log4u  - Log your development work</title>
        <meta property="og:site_name" content="Log4u " />
        <meta
          property="og:url"
          content="https://themeforest.net/user/ib-themes"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Log4u : Log your work for behavioral interview and technical interview"
        />
        <meta name="keywords" content="interview preparation, job interview, interview tips, mock interviews, behavioral interview, technical interview, coding practice, resume writing, career development, job search, interview coaching, interview questions, company reviews, networking, professional development"/>
          <meta name="description" content="Prepare for behavioral interviews with our comprehensive resources. Learn how to answer common behavioral questions, master the STAR method, and showcase your skills and experiences effectively. Enhance your interview performance and increase your chances of success in behavioral interviews."/>
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      {/* End Scroll top */}

      <AllRoutes />
    </>
  );
};

export default App;
